import { template as template_c23632058698423cbe5e204fc75b238e } from "@ember/template-compiler";
export default template_c23632058698423cbe5e204fc75b238e(`
  <ul class="responsive-list-wide-wrap">
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
