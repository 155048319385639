import { template as template_d50e993e1b2b4d9688028f159168e329 } from "@ember/template-compiler";
import PixBackgroundHeader from '@1024pix/pix-ui/components/pix-background-header';
import PixBlock from '@1024pix/pix-ui/components/pix-block';
import CertificationStarter from '../certification-starter';
import CompanionBlocker from '../companion/blocker';
import AppLayout from '../global/app-layout';
export default template_d50e993e1b2b4d9688028f159168e329(`
  <AppLayout>
    <CompanionBlocker>
      <main class="main" role="main">
        <PixBackgroundHeader id="main">
          <PixBlock @shadow="heavy" class="certification-start-page__block">
            <CertificationStarter @certificationCandidateSubscription={{@certificationCandidateSubscription}} />
          </PixBlock>
        </PixBackgroundHeader>
      </main>
    </CompanionBlocker>
  </AppLayout>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
